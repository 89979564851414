<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->


<ul class="navbar-nav float-left mr-auto">
  <li class="nav-item search-box" *ngIf="false">
    <a (click)="showSearch = !showSearch" class="nav-link waves-effect waves-dark" href="javascript:void(0)">
      <div class="d-flex align-items-center">
        <i class="mdi mdi-magnify font-20 mr-1"></i>
        <div class="ml-1 d-none d-sm-block">
          <span i18n="@@search">Search</span>
        </div>
      </div>
    </a>
    <form class="app-search position-absolute" [ngClass]="{'show-search': showSearch}">
      <input type="text" class="form-control" placeholder="Search &amp; enter" i18n-placeholder="@@searchAndEnter">
      <a class="srh-btn" (click)="showSearch = !showSearch">
        <i class="ti-close"></i>
      </a>
    </form>
  </li>
</ul>
<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-right">
  <!-- ============================================================== -->
  <!-- Quick Access -->
  <!-- ============================================================== -->
  <!-- <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
        <a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark" href="javascript:void(0)" id="2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="font-22 mdi mdi-cursor-pointer"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right mailbox animated bounceInDown" aria-labelledby="2" ngbDropdownMenu>
            <span class="with-arrow">
                <span class="bg-danger"></span>
            </span>
            <ul class="list-style-none">
                <li>
                    <div class="drop-title text-white bg-danger">
                        <h4 class="m-b-0 m-t-5">Quick Access</h4> -->
  <!-- <span class="font-light">Messages</span> -->
  <!-- </div>
                </li>
                <li> -->
  <!-- <div class="message-center message-body" [perfectScrollbar]="config"> -->
  <!-- Quick -->
  <!-- <span class="message-item" *ngFor="let quick of quickList">
                            <a [routerLink]="[quick.route]">
                                <span class="mail-contnet">
                                    <h5 class="message-title">{{quick.title}}</h5>
                                </span>
                        </a>
                        </span>
                    </div>
                </li>
            </ul>
        </div>
    </li> -->

  <!-- ============================================================== -->
  <!-- Messages -->
  <!-- ============================================================== -->
  <!-- <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
        <a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark" href="javascript:void(0)" id="2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="font-22 mdi mdi-email-outline"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right mailbox animated bounceInDown" aria-labelledby="2" ngbDropdownMenu>
            <span class="with-arrow">
                <span class="bg-danger"></span>
            </span>
            <ul class="list-style-none">
                <li>
                    <div class="drop-title text-white bg-danger">
                        <h4 class="m-b-0 m-t-5">5 New</h4>
                        <span class="font-light">Messages</span>
                    </div>
                </li>
                <li>
                    <div class="message-center message-body" [perfectScrollbar]="config"> -->


  <!-- Message -->


  <!-- <a href="javascript:void(0)" class="message-item" *ngFor="let mymessage of mymessages">
                            <span class="user-img">
                                <img src="{{mymessage.useravatar}}" alt="user" class="rounded-circle">
                                <span class="profile-status {{mymessage.status}} pull-right"></span>
                            </span>
                            <span class="mail-contnet">
                                <h5 class="message-title">{{mymessage.from}}</h5>
                                <span class="mail-desc">{{mymessage.subject}}</span>
                            <span class="time">{{mymessage.time}}</span>
                            </span>
                        </a>
                    </div>
                </li>
                <li>
                    <a class="nav-link text-center link" href="javascript:void(0);">
                        <b>See all e-Mails</b>
                        <i class="fa fa-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </li> -->
  <!-- ============================================================== -->
  <!-- End Messages -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- Comment -->
  <!-- ============================================================== -->
  <li class="nav-item dropdown border-right" ngbDropdown placement="bottom-right">
    <a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark" href="javascript:void(0)"
      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="goToApproval()">
      <!-- <i class="mdi mdi-bell-outline font-22"></i> -->
      <span
        class="{{ isShake == true ? 'badge badge-pill badge-info noti shake' : 'badge badge-pill badge-info noti' }}"
        *ngIf="isPendingApprovalMoreThanZero">{{ pendingApprovalNumber }}</span>
      <i class="mdi mdi-playlist-check font-22"></i>
    </a>
    <!-- <a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark" href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="goToPool()">
            <i class="mdi mdi-playlist-check font-22"></i>
        </a> -->
    <!-- <div class="dropdown-menu dropdown-menu-right mailbox animated bounceInDown" ngbDropdownMenu>
            <span class="with-arrow">
                <span class="bg-primary"></span>
            </span>
            <ul class="list-style-none">
                <li>
                    <div class="drop-title bg-primary text-white">
                        <h4 class="m-b-0 m-t-5">4 New</h4>
                        <span class="font-light">Notifications</span>
                    </div>
                </li>
                <li>
                    <div class="message-center notifications" [perfectScrollbar]="config"> -->
    <!-- Message -->
    <!-- <a href="javascript:void(0)" class="message-item" *ngFor="let notification of notifications">
                            <span class="btn btn-circle {{notification.btn}}">
                                <i class="{{notification.icon}}"></i>
                            </span>
                            <span class="mail-contnet">

                                <a [routerLink]="[notification.route]">
                                    <span class="mail-contnet">
                                        <h5 class="message-title">{{notification.title}}</h5>
                                    </span>
                        </a>

                        <span class="mail-desc">{{notification.subject}}</span>
                        <span class="time">{{notification.time}}</span>
                        </span>
                        </a>
                    </div>
                </li> -->
    <!-- <li>
                    <a class="nav-link text-center m-b-5" href="javascript:void(0);">
                        <strong>Check all notifications</strong>
                        <i class="fa fa-angle-right"></i>
                    </a>
                </li> -->
    <!-- </ul>
        </div> -->
  </li>
  <li class="nav-item dropdown border-right" ngbDropdown placement="bottom-right">
    <a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark" href="javascript:void(0)"
      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="changeLanguage()">
      <i class="fa fa-globe" style="font-size: 18px"></i>
      <!-- <i class="mdi mdi-web-box font-22"></i> -->
    </a>
  </li>
  <!-- ============================================================== -->
  <!-- End Comment -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->


  <!-------------------------------------------------------------------->

  <li class="nav-item dropdown" ngbDropdown placement="bottom-right" *ngIf="mapUsers.length > 0">
    <a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"
      href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <img src="assets/images/users/organ.png" alt="user" class="rounded-circle" width="50">
      <span class="m-l-5 font-medium d-none d-sm-inline-block">{{ locale == 'ar-AE' ? selectedMapUser.nameArabic
        :selectedMapUser.name }}<i class="mdi mdi-chevron-down"></i></span>
    </a>
    <div class="dropdown-menu dropdown-menu-right user-dd animated flipInY" ngbDropdownMenu *ngIf="mapUsersLoaded">
      <span class="with-arrow">
        <span class="bg-info"></span>
      </span>
      <div class="d-flex no-block align-items-center p-15 bg-info text-white m-b-10">

        <div class="m-l-10">
          <h4 class="m-b-0">{{ locale == 'ar-AE' ? selectedMapUser.nameArabic : selectedMapUser.name }}</h4>
        </div>
      </div>

      <a class="dropdown-item" href="javascript:void(0)" *ngFor="let mapUser of mapUsers"
        (click)="onValueChange(mapUser)">
        {{ locale == 'ar-AE' ? mapUser.nameArabic : mapUser.name }}</a>
    </div>
  </li>

  <!-- User profile and search -->
  <!-- ============================================================== -->

  <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
    <a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"
      href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <img src="assets/images/users/1.jpg" alt="user" class="rounded-circle" width="40">
      <span class="m-l-5 font-medium d-none d-sm-inline-block">{{ name }}
        <i class="mdi mdi-chevron-down"></i></span>
    </a>
    <div class="dropdown-menu dropdown-menu-right user-dd animated flipInY" ngbDropdownMenu>
      <span class="with-arrow">
        <span class="bg-primary"></span>
      </span>
      <div class="d-flex no-block align-items-center p-15 bg-primary text-white m-b-10">
        <!-- <div class="">
                    <img src="assets/images/users/1.jpg" alt="user" class="img-circle" width="60">
                </div> -->
        <div class="m-l-10">
          <h4 class="m-b-0">{{ name }}</h4>
          <p class=" m-b-0">{{ email }}</p>
        </div>
      </div>
      <!-- <a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-user m-r-5 m-l-5"></i> My Profile</a>
            <a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-wallet m-r-5 m-l-5"></i> My Balance</a>
            <a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-email m-r-5 m-l-5"></i> Inbox</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-settings m-r-5 m-l-5"></i> Account Setting</a>
            <div class="dropdown-divider"></div> -->
      <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">
        <i class="fa fa-power-off m-r-5 m-l-5"></i> {{ locale == 'ar-AE' ? 'تسجيل الخروج' : 'Logout' }}</a>
      <!-- <div class="dropdown-divider"></div>
            <div class="p-l-30 p-10">
                <a href="javascript:void(0)" class="btn btn-sm btn-success btn-rounded">View Profile</a>
            </div> -->
    </div>
  </li>



  <!-- ============================================================== -->
  <!-- User profile and search -->
  <!-- ============================================================== -->
</ul>



<ng-template #loading let-modal>
  <app-loader-popup></app-loader-popup>
</ng-template>
